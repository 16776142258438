import "./css/PrivacyPolicy.css";
import HeaderNew from "./HeaderNew";

function PrivacyPolicy() {
  return (
    <>
      {" "}
      <div className="headerBttmMargin2">
        {" "}
        <HeaderNew />
      </div>
      <section id="about">
        <h2>Privacy & Policy</h2>
      </section>
      <div className="privacy_policy">
        <div className="container privacy_policy_wrapper">
          <div className="privacy_policy_container">
            <div>
              <strong>
                This Privacy Policy describes how ChatGPT Notepad ("we," "us,"
                or "our") collects, uses, and discloses your information when
                you use our online Notepad service ("Service").
              </strong>
            </div>
            <div>
              <strong>
                <h6>1. Information We Collect </h6>
              </strong>
              <p>
                <strong style={{ color: "black" }}>
                  1.1. User-Provided Information:
                </strong>
                &nbsp; When you use our Service, you may provide us with text or
                content to be stored or processed by Notepad. We collect and
                store this information solely to provide the Service to you.
              </p>
              <p>
                <strong style={{ color: "black" }}>
                  1.2. Automatically Collected Information:
                </strong>
                &nbsp; We may automatically collect certain information when you
                use the Service, such as your IP address, browser type, device
                type, and usage patterns. This information analyzes trends,
                administers the Service, and improves user experience.
              </p>
            </div>
            <div>
              <strong>
                <h6>2. How We Use Your Information</h6>
              </strong>
              <p>
                <strong style={{ color: "black" }}>2.1.</strong>&nbsp;We use our
                collected information to operate, maintain, and improve the
                Service.
              </p>
              <p>
                <strong style={{ color: "black" }}>2.2.</strong>&nbsp;We may use
                your information to communicate, respond to your inquiries, and
                provide customer support.
              </p>
              <p>
                <strong style={{ color: "black" }}>2.3.</strong>&nbsp;We may use
                aggregated and anonymized data for analytical purposes to
                understand how the Service is used and to improve its
                functionality.
              </p>
            </div>
            <div>
              <strong>
                <h6>3. Data Security</h6>
              </strong>
              <p>
                <strong style={{ color: "black" }}>3.1.</strong>&nbsp;We take
                reasonable measures to protect your information from
                unauthorized access, disclosure, alteration, or destruction.
                However, no method of transmission over the Internet or
                electronic storage is completely secure, so we cannot guarantee
                absolute security.
              </p>
            </div>
            <div>
              <strong>
                <h6>4. Disclosure of Your Information</h6>
              </strong>
              <p>
                <strong style={{ color: "black" }}>4.1.</strong>&nbsp;We do not
                sell, trade, or otherwise transfer your information to third
                parties without your consent, except as described in this
                Privacy Policy or as required by law.
              </p>
            </div>
            <div>
              <div>
                <strong>
                  <h6>5. Data Retention</h6>
                </strong>
              </div>
              <p>
                <strong style={{ color: "black" }}>5.1.</strong>&nbsp;We will
                retain your information for as long as necessary to fulfill the
                purposes outlined in this Privacy Policy unless a longer
                retention period is required or permitted by law.
              </p>
            </div>

            <div>
              <strong>
                <h6>6. Children's Privacy</h6>
              </strong>
              <p>
                <strong style={{ color: "black" }}>6.1.</strong>&nbsp;The
                Service is not directed to individuals under the age of 13, and
                we do not knowingly collect personal information from children
                under 13. If we become aware that a child under 13 has provided
                us with personal information, we will take steps to delete such
                information.
              </p>
            </div>

            <div>
              <strong>
                <h6>7. Changes to Privacy Policy</h6>
              </strong>
              <p>
                <strong style={{ color: "black" }}>7.1.</strong>&nbsp;We may
                update this Privacy Policy from time to time. If we make
                material changes, we will notify you by posting the new Policy
                on this page.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
