import React from "react";
import "./css/PrivacyPolicy.css";
import HeaderNew from "./HeaderNew";
function TermsOfUse() {
  return (
    <>
      {" "}
      <div className="headerBttmMargin2">
        {" "}
        <HeaderNew />
      </div>
      <section id="about">
        <h2>Terms & Use</h2>
      </section>
      <div className="privacy_policy">
        <div className="container privacy_policy_wrapper">
          <div className="privacy_policy_container">
            <div>
              <strong>
                These Terms and Conditions ("Terms") govern your access to and
                use of ChatGPT Notepad ("Service"), operated by Triosoft
                Technologies ("we," "us," or "our"). You agree to be bound by
                these Terms by accessing or using the Service. If you disagree
                with any part of the Terms, you may not access the Service.
              </strong>

            </div>
            <div>
              <strong>
                <h6>1. Use of the Service</h6>
              </strong>
              <p>
                <strong style={{ color: "black" }}>1.1.</strong>&nbsp;The
                Service is provided on an "as is" and "as available" basis for
                your personal, non-commercial use.
              </p>
              <p>
                <strong style={{ color: "black" }}>1.2.</strong>&nbsp;You must
                not use the Service for any illegal or unauthorized purpose. You
                agree to comply with all applicable laws and regulations.
              </p>
              <p>
                <strong style={{ color: "black" }}>1.3.</strong>&nbsp;You are
                responsible for maintaining the security of your account and
                password. You must notify us immediately of any unauthorized use
                of your account or any other breach of security.
              </p>
            </div>
            <div>
              <strong>
                <h6>2. User Content</h6>
              </strong>
              <p>
                <strong style={{ color: "black" }}>2.1.</strong>&nbsp;You retain
                ownership of any content you upload, create, or store using the
                Service ("User Content").
              </p>
              <p>
                <strong style={{ color: "black" }}>2.2.</strong>&nbsp;By
                uploading, creating, or storing User Content, you grant us a
                non-exclusive, transferable, sublicensable, royalty-free,
                worldwide license to use, store, reproduce, modify, adapt, and
                distribute your User Content for the purpose of providing and
                improving the Service.
              </p>
              <p>
                <strong style={{ color: "black" }}>2.3.</strong>&nbsp;You are
                solely responsible for the accuracy, quality, and legality of
                your User Content and for ensuring that you have all necessary
                rights to upload, create, or store such content.
              </p>
            </div>
            <div>
              <strong>
                <h6>3. Intellectual Property</h6>
              </strong>
              <p>
                <strong style={{ color: "black" }}>3.1.</strong>&nbsp; The
                Service and its original content, features, and functionality
                are owned by [Your Company Name]. They are protected by
                copyright, trademark, patent, trade secret, and other
                intellectual property or proprietary rights laws.
              </p>
            </div>
            <div>
              <div>
                <strong>
                  <h6>4. Limitation of Liability</h6>
                </strong>
              </div>
              <p>
                <strong style={{ color: "black" }}>4.1.</strong>&nbsp;We shall
                not be liable for any indirect, incidental, special,
                consequential, or punitive damages arising from or related to
                your use of the Service.
              </p>
              <p>
                <strong style={{ color: "black" }}>4.2.</strong>&nbsp;In no
                event shall our total liability to you for all claims related to
                the Service exceed the amount paid by you, if any, for accessing
                or using the Service during the twelve months preceding the
                claim.
              </p>
            </div>
            <div>
              <strong>
                <h6>5. Termination</h6>
              </strong>
              <p>
                <strong style={{ color: "black" }}>5.1.</strong>&nbsp;We may
                terminate or suspend your access to the Service immediately,
                without prior notice or liability, for any reason whatsoever,
                including without limitation if you breach the Terms.
              </p>
              <p>
                <strong style={{ color: "black" }}>5.2.</strong>&nbsp;Upon
                termination, your right to use the Service will immediately
                cease, and any User Content you have stored on the Service may
                be deleted.
              </p>
            </div>
            <div>
              <strong>
                <h6>6. Governing Law</h6>
              </strong>
              <p>
                <strong style={{ color: "black" }}>6.1.</strong>&nbsp;These
                Terms shall be governed by and construed in accordance with the
                laws of India, without regard to its conflict of law provisions.
              </p>
            </div>
            <div>
              <strong>
                <h6>7. Governing Law</h6>
              </strong>
              <p>
                <strong style={{ color: "black" }}>7.1.</strong>&nbsp;These
                Terms and Conditions shall be governed by and construed in
                accordance with the laws of [States of Madhya Pradesh and
                Chhattisgarh, India], without regard to its conflict of law
                provisions.
              </p>
            </div>
            <div>
              <strong>
                <h6>8. Changes to Terms</h6>
              </strong>
              <p>
                <strong style={{ color: "black" }}>8.1.</strong>&nbsp;We reserve
                the right, at our sole discretion, to modify or replace these
                Terms at any time. If a revision is material, we will provide at
                least 30 days' notice before any new terms taking effect.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsOfUse;
